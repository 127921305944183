import { firebase, useEmulators } from "./firebase";
import { getLog } from "./log";
let log = getLog("db-client");

export let db = firebase.firestore();

if (useEmulators && (window.location.hostname === "localhost" || window.location.hostname.startsWith("192.168"))) {
  log.log("useEmulators", useEmulators);
  db.useEmulator("localhost", 8080);
}

export let storage = firebase.storage();

export let database = firebase.database();

export let serverTimestamp = () => firebase.firestore.FieldValue.serverTimestamp();

export let fieldDelete = () => firebase.firestore.FieldValue.delete();

export let timestampFromDate = (date) => firebase.firestore.Timestamp.fromDate(date);
