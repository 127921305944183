import { getTimeMS } from "./functions";
import { epochms } from "./utils";
import { getLog } from "./log"
let log = getLog('utils');

let delta = 0;

export async function initServerTime() {
  log.log("initServerTime started");
  try {
    let serverTime = (await getTimeMS()).data;
    let browserTime = epochms();
    delta = browserTime - serverTime;
    log.log(`initServerTime delta: ${delta}, server: ${serverTime}, browser: ${browserTime}`);
  } catch (e) {
    log.error("server time initialization failed. using delta:0", e);
  }
}

export function serverEpoch() {
  return Math.floor(serverEpochMS() / 1000);
}

export function serverEpochMS() {
  return Math.floor(epochms() - delta);
}
