import  Vue  from  "vue";
import { timeSince } from './services/utils';
import { serverEpoch } from './services/server-time';

Vue.filter("timesince", function (value) {
  if (!value) return "";
  return timeSince(serverEpoch, value);
});

Vue.filter("timetoISO", function (value) {
  return new Date(value).toISOString();
});

Vue.filter("jsonstringify", function (value) {
  return JSON.stringify(value);
});

Vue.filter("capitalize", function (value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

import moment from 'moment-timezone';

Vue.filter("formatDate", function (value, format, tz) {
  if (value) {
    value = moment(value.toISOString());
    if (tz)
      value = value.tz(tz);
    return value.format(format || 'MM/DD/YYYY LT');
  }
});

Vue.filter("pluralize", function (value, prefix) {
  return prefix + (value > 1 ? "s" : "");
});
