import NotFoundComponent from "@/views/NotFoundComponent.vue"
// Admin Route
export let adminRoute = {
    path: "/admin",
    component: () => import("./AdminRouter.vue"),
    children: [
        {
            path: "",
            redirect: { path: "events" }
        },
        {
            path: "events",
            component: () => import("./Events.vue")
        },
        {
            path: "event/:id",
            component: () => import("./Event.vue"),
        },
        {
            path: "users",
            component: () => import("./Users.vue")
        },
        {
            path: 'studio',
            component: () => import("./Studio.vue")
        },
        {
            path: "logs",
            component: () => import("./Logs.vue")
        },
        {
            path: "accounts",
            component: () => import("./Accounts.vue")
        },
        {
            path: "ops",
            component: () => import("./Ops.vue")
        },
        {
            path: '*', 
            component: NotFoundComponent 
        }        
    ]
};

