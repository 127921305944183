<template>
<div class="dbg alert-info">
  <span v-if="label" class="alert-warning">{{ label }}:</span>
  <span v-if="obj">
    {{ obj.id }} 
    <span class="badge badge-secondary" v-if="filter">filtered</span>
    <span v-if="obj">
      <button class="btn btn-secondary badge" @click="show" v-if="openned">-</button>
      <button class="btn btn-secondary badge" @click="show" v-else>+</button>
      <span v-if="openned">
        <button class="btn btn-secondary badge ml-1" @click="format" v-if="pretty">inline</button>
        <button class="btn btn-secondary badge ml-1" @click="format" v-else>pretty</button>
      </span>
    </span>
    <span v-if="openned">
      <span v-if="pretty">
        <vue-json-pretty :data="obj" :deep="1"/>
      </span><span v-else>
        {{ obj }}
      </span>
    </span>
  </span><span v-else>
    {{ objPath }}
  </span>
  
</div>
</template>

<script>
import { db } from '@/services/db';
import * as pick from "object.pick";
import { getLog } from "@/services/log";
// eslint-disable-next-line no-unused-vars
let log = getLog('debug-obj');

export default {
  props: {
    label: String,
    objPath: String,
    objData: null,
    expand: {
      type: Boolean,
      default: false,
    },
    folded: {
      type: Boolean,
      default: false,
    },
    filter: Array,
    filterOut: Array,
  },
  data() {
    return {
      openned: false,
      pretty: false,
      loadedObj: null,
      filteredObj: null,
    }
  },
  computed: {
    obj() {
      return this.filteredObj || this.objData || this.loadedObj;
    }
  },
  watch: {
    objPath() {
      this.$bind("loadedObj", db.doc(this.objPath));
    },
    objData() {
      this.updateFilter();
    }
  },
  mounted() {
    this.openned = !this.folded;
    this.pretty = this.expand;
    if (this.objPath)
      this.$bind("loadedObj", db.doc(this.objPath));
    else
      this.updateFilter();
  },
  methods: {
    updateFilter() {
      if (!this.objData) {
        this.filteredObj = null;
        return;
      }
      if (this.filter)
        this.filteredObj = pick(this.objData, this.filter); 
      if (this.filterOut) {
        //log.log("objData", this.objData);
        this.filteredObj = Object.assign({}, this.objData);
        this.filterOut.forEach(key => {
          if (this.filteredObj[key])
            this.filteredObj[key] = "*filtered out*";
        });
      }
      //log.log("filtered", this.filteredObj);
    },
    show() {
      this.openned = !this.openned;
    },
    format() {
      this.pretty = !this.pretty;
    }
  }
}
</script>

<style scoped>

.dbg {
  max-width: 100%;
}

</style>