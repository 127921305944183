//let productionStreamingServerIp = "wss://streamozoa.ngrok.io";
let productionStreamingServerIp = "wss://streaming.happyhour.live";

let baseConfig = {
  showBetaWarning: false,
  // Servers
  //streamingServerIp: "ws://localhost:3000",
  streamingServerIp: productionStreamingServerIp,
  useStreamingServerForServing: false,
  showHelpButton: false,
  iconPath: "img/icons",
  //contentServerHost: "http://livem-demo.appspot.com.storage.googleapis.com",
  contentServerHost: "http://content.veeed.com.global.prod.fastly.net",

  //devServerCorsCoop: true,
}

// Environment determination
/*
if (global.window) {
  if (global.window.location.hostname.startsWith("app.")
    || global.window.location.hostname.startsWith("veeed.com"))
    baseConfig.environment = null;
  else if (global.window.location.hostname.startsWith("app-staging."))
    baseConfig.environment = "staging";
  else
    baseConfig.environment = "dev";
}
*/

if (process.env.NODE_ENV == 'development') {
  baseConfig.environment = "dev";
}

// Production override
if (process.env.NODE_ENV == 'production') {
  baseConfig.environment = null;
  baseConfig.streamingServerIp = productionStreamingServerIp;
}

let happyHour = {
  // Branding
  productName: "Happy Hour",
  productNameIcon: "HappyHour",
  productNameLC: "happyhour",
  tagLine: "Live Events Where People Connect",
  companyName: "Massive Finger Inc. d/b/a Happy Hour",
  eventName: "Live Event",
  contactEmail: "hello@happyhour.live",
  productURL: "https://www.happyhour.live",
  appURL: "https://app.happyhour.live",

  // Side Menu Items
  menuItems: ['events', 'account'],

  // Flags
  useRtcComponent: "roomRTC", // roomRTC | peerRTC
  useiOSNativeWebRTC: false,
  limitedAvailability: true,
  redirectToSignupFromHomepage: false,
  skipAccount: true,
  skipToU: true, // Skiping ToU because they are provided on the sign in screen.
  disableEventCalendar: false,
  eventsShowPublic: false,
  eventsAllowCreation: true, // Should be changed on an invidual basis.  
  sendEmailNotifications: false,

  // Assets
  assets(c) {
    c.productLogoTitle = require("@/assets/happyhour/title.png");
    c.productLogoSmall = require("@/assets/happyhour/logo_small.png");
    return c;
  }
}

let veeedConfig = {
  // Branding
  productName: "Veeed",
  productNameIcon: "Veeed",
  productNameLC: "veeed",
  tagLine: "Video spaces made for collaboration",
  companyName: "Massive Finger Inc. d/b/a Veeed",
  eventName: "Live Event",
  contactEmail: "contact@veeed.com",
  productURL: "https://www.veeed.com",
  appURL: "https://veeed.com",

  // Builder.io API key
  //builderIoApiKey: "1c330c1e07d145c398b4bce6ee0ad4eb",
  waitlist: true,

  // Side Menu Items
  menuItems: ['events', 'account'],

  // Flags
  useRtcComponent: "roomRTC", // roomRTC | peerRTC
  useiOSNativeWebRTC: false,
  limitedAvailability: true,
  redirectToSignupFromHomepage: false,
  skipAccount: false,
  skipToU: true, // Skiping ToU because they are provided on the sign in screen.
  disableEventCalendar: false,
  eventsShowPublic: false,
  eventsAllowCreation: true, // Should be changed on an invidual basis.  
  sendEmailNotifications: false,

  // Assets
  assets(c) {
    c.productLogoTitle = require("@/assets/veeed/title.png");
    c.productLogoSmall = require("@/assets/veeed/logo_small.png");
    return c;
  }
}

let officeHours = {
  // Branding
  productName: "Office Hours",
  productNameIcon: "OfficeHours",
  productNameLC: "officehours",
  tagLine: "Quick meetings",
  companyName: "Massive Finger Inc. d/b/a Office Hours",
  eventName: "Meeting",
  contactEmail: "hello@officehours.one",
  productURL: "https://www.officehours.one",
  appURL: "https://app.officehours.one",

  // Side Menu Items
  menuItems: [],

  // Flags
  useRtcComponent: "roomRTC", // roomRTC | peerRTC
  disableEventCalendar: true,
  limitedAvailability: true,
  redirectToSignupFromHomepage: false,
  skipAccount: true,
  skipToU: true, // Skiping ToU because they are provided on the sign in screen.
  sendEmailNotifications: true,

  // Assets
  assets(c) {
    c.productLogoTitle = require("@/assets/officehours/title_oh.png");
    c.productLogoSmall = require("@/assets/officehours/logo_small_oh.png");
    return c;
  }
}

let fnStatsConfig = {
  // Branding
  productName: "FN Stats",
  productNameIcon: "FNStats",
  productNameLC: "fnstats",
  tagLine: "Stats for Fortnite Creative Maps",
  companyName: "MMXXIV LLC",
  eventName: "Live Event",
  contactEmail: "sanx@fxteam.net",
  productURL: "https://fnstats.web.app",
  appURL: "https://fnstats.web.app/fnstats",
  homeRedirect: { path: "fnstats" },
  iconPath: "img/fnstats-icons",
  menuItems: [],

  // Assets
  assets(c) {
    c.productLogoTitle = require("@/assets/fnstats/title_fns.png");
    c.productLogoSmall = require("@/assets/fnstats/logo_small_fns.png");
    return c;
  }
}

let pChatGPTConfig = {
  // Branding
  productName: "PChat GPT",
  productNameIcon: "PChatGPT",
  productNameLC: "pchatgpt",
  tagLine: "Personal Toolkit for ChatGPT",
  companyName: "MMXXIV LLC",
  eventName: "Live Event",
  contactEmail: "sanx@fxteam.net",
  productURL: "https://pchatgpt.web.app",
  appURL: "https://pchatgpt.web.app/notes",
  homeRedirect: { path: "notes" },
  themeColor: "#f82f45",
  iconPath: "img/pchatgpt-icons",
  menuItems: [],

  // Assets
  assets(c) {
    c.productLogoTitle = require("@/assets/fnstats/title_fns.png");
    c.productLogoSmall = require("@/assets/fnstats/logo_small_fns.png");
    return c;
  }
}

function getConfig(configName) {
  let configs = {
    oh: officeHours,
    hh: happyHour,
    veeed: veeedConfig,
    fnstats: fnStatsConfig,
    pchatgpt: pChatGPTConfig,
  }
  let config = Object.assign(baseConfig, configs[configName] || happyHour);
  config.appVariant = configName;

  if (process.env.VUE_APP_PLATFORM == "ios") {
    config = Object.assign(config, {
      limitedAvailability: false,
      redirectToSignupFromHomepage: true,
      signInOptionsNoGoogle: true,
      skipAccount: false,
    });
  }

  let appConfig = typeof window == "undefined" ? config : config.assets(config);

  return appConfig;
}

let configName = process.env.VUE_APP_VARIANT || "hh";

let appConfig = getConfig(configName);

let appConfigPlugin = {
  install(Vue) {
    Vue.prototype.$app = appConfig;
  }
}

module.exports = {
  productionStreamingServerIp,
  appConfig,
  appConfigPlugin,
  veeedConfig,
  fnStatsConfig,
  pChatGPTConfig,
}