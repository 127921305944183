import NotFoundComponent from "@/views/NotFoundComponent.vue"

export let notesRoute = { 
  path: "/notes",
  component: () => import("./NotesRouter.vue"),
  children: [
    {
      path: "simple-chat",
      component: () => import("./SimpleChat.vue"),
    },
    {
      path: "chat",
      component: () => import("./Chat.vue"),
    },
    {
      path: "notes",
      component: () => import("./Notes.vue"),
    },
    {
      path: "login",
      meta: { nextRoute: "/notes/notes" },
      component: () => import("../fnstats/Login.vue"),
    },
    {
      path: "workflow",
      component: () => import("./Workflow.vue"),
    },
    {
      path: '*', 
      component: NotFoundComponent 
    }
  ]
}
