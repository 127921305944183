import { getLog } from "@/services/log";
let log = getLog("notes-store");
import { db, serverTimestamp } from "@/services/db";
import { app } from "@/services/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { removeUndefined } from "@/services/utils";

export let storePlugin = {
  install(Vue) {
    Vue.prototype.$store = Vue.observable({});
    let $store = Vue.prototype.$store;
    $store.setState = function(state) {
      log.log("setState");
      $store.state = state;
    },
    $store.setAccount = async function(account) {
      log.log("setAccount");
      $store.account = account;
      if (account) {
        log.log("loading accountInfo");
        await $store.state.$bind("accountInfo", db.collection("AllAccounts").doc(account.uid));
        $store.state.settings = $store.state.accountInfo.settings;
        if ($store.state.accountInfo?.teams?.length) {
          log.log("loading team");
          await $store.state.$bind("teamInfo", db.collection("AllTeams").doc($store.state.accountInfo.teams[0]));
        }
      }
      // logout
      else if ($store.state.accountInfo) {
        $store.state.$unbind("accountInfo");
        $store.state.$unbind("teamInfo");
      }
    },
    $store.hasAccess = async function(productId) {
      log.log("hasAccess");
      if (!$store.account)
        return false;
      if (!$store.state.accountInfo) {
        await $store.state.$bind("accountInfo", db.collection("AllAccounts").doc($store.account.uid));
      }
      return $store.state.accountInfo ? $store.state.accountInfo[productId] : false || false;
    },
    $store.loadNotes = async function() {
      log.log("loadNotes");
      $store.getConfig();
      await $store.state.$bind("notes", db.collection(`NotesItems/${$store.account.uid}/items`));
    },
    $store.addNote = function(newNote) {
      log.log("addNote");
      newNote.createdTimestamp = serverTimestamp();
      newNote.lastEditTimestamp = serverTimestamp();
      db.collection(`NotesItems/${$store.account.uid}/items`).add(newNote)
      .then(() => {
        log.log("New note added successfully");
      })
      .catch((error) => {
        log.error("Error adding new note: ", error);
      });
    },
    $store.getConfig = async function() {
      if ($store.config) {
        return $store.config;
      }
      // Get API key from environment variables
      const getConfig = httpsCallable(getFunctions(app), "notes-getConfig");
      try {
        const response = await getConfig();
        $store.config = response.data;
        log.log("Received config.");
      } catch (error) {
        log.error(error);
      }
    }
    $store.saveSettings = async function(settings) {
      log.log("saveSettings", settings);
      settings = removeUndefined(settings);
      db.collection("AllAccounts").doc($store.state.accountInfo.id).set({settings}, {merge: true});
    }
  },
}
