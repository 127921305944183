<template>
<div class="alert-info mb-1">
  <div>
    <button class="btn btn-sm btn-primary" @click="show = !show;">{{ label || 'Toggle Debug' }}</button>
  </div>
  <div v-if="show">
    <slot/>
  </div>
</div>
</template>

<script>
export default {
  props: {
    label: String,
    expand: Boolean,
  },
  data() {
    return {
      show: false,
    }
  },
  mounted() {
    this.show = this.expand;
  }
}
</script>

<style>

</style>