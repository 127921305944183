import { getLog } from "./log"
let log = getLog('utils');

export function getBrowser() {
  // https://stackoverflow.com/a/13348618/318839
  // please note, 
  // that IE11 now returns undefined again for window.chrome
  // and new Opera 30 outputs true for window.chrome
  // but needs to check if window.opr is not undefined
  // and new IE Edge outputs to true now for window.chrome
  // and if not iOS Chrome check
  // so use the below updated condition
  var isChromium = window.chrome;
  var winNav = window.navigator;
  var vendorName = winNav.vendor;
  log.log(`UA: ${winNav.userAgent}`);
  var isOpera = typeof window.opr !== "undefined";
  var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
  var isIOSChrome = winNav.userAgent.match("CriOS");
  var isSafari = winNav.userAgent.toLowerCase().indexOf("safari") > -1;
  var isMobile = winNav.userAgent.toLowerCase().indexOf("mobile") > -1;
  var isLiveWrapper = winNav.userAgent.toLowerCase().indexOf("live-wrapper") > -1;

  if (isIOSChrome) {
    // is Google Chrome on IOS
  } else if(
    isChromium !== null &&
    typeof isChromium !== "undefined" &&
    vendorName === "Google Inc." &&
    isOpera === false &&
    isIEedge === false
  ) {
    // is Google Chrome
    if (isMobile)
      return "mobilechrome";
    else
      return "chrome";
  } else if (isSafari) {
    // is Safari
    if (isMobile)
      return "mobilesafari";
    else
      return "safari";
  } else if (isLiveWrapper) { 
    // not Google Chrome 
    return "live-wrapper";
  }
  return "other";
}

export function getURLRoot() {
  return window.location.protocol + '//' + window.location.host;
}

export function getHTTPSfromWS(url) {
  return "http" + url.slice(2);
}

export let formatTime = (r) => {
  let res = `${Math.floor(r / 60)}:${Math.floor(r % 60).toString().padStart(2, '0')}`;
  return res;
}

export let formatTimer = (start, timer, currentTime) => {
  let time = currentTime - start;
  let r = (parseFloat(timer) * 60) - time;
  if (r < 0)
    r = 0;
  let res = formatTime(r);
  return res;
}

export function timeSince(epochfun, epochdate) {
  let epoch = epochdate.seconds;

  var seconds = Math.floor(epochfun() - epoch);
  if (seconds < 2)
    return "now";

  var interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return interval + " years ago";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval + " months ago";
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval + " days ago";
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval + " hours ago";
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

export function findLast(array, predicate) {
	for (let i = array.length - 1; i >= 0; --i) {
		const x = array[i];
		if (predicate(x)) {
			return x;
		}
	}
}

export function formatQuestions(questions) {
  return "-" + questions.join("\n-");
}

export function cleanFilename(name) {
  return name.replace(/[^a-zA-Z0-9_]+/g, '-').toLowerCase();
}

export function bindMapFromArray(that, prop, proparr) {
  that.$watch(proparr, (docArr) => {
    let docMap = {};
    for (let doc of docArr)
      docMap[doc.id] = doc;
    that.$set(that, prop, docMap);
    //log.log(`bindMap: converted array to ${prop} map`, docMap);
  });
}

export function bindMap(that, prop, collection) {
  return new Promise((resolve, reject) => {
    //log.log(`bindMap: init ${prop}`);
    let proparr = prop + "_asArray";
    if (that[proparr] === undefined)
      throw `bindMap: you need to declare a reactive property "${proparr}"`;
    bindMapFromArray(that, prop, proparr)
    that.$watch(proparr, (docArr) => {
      let docMap = {};
      for (let doc of docArr)
        docMap[doc.id] = doc;
      that.$set(that, prop, docMap);
      //log.log(`bindMap: converted array to ${prop} map`, docMap);
    })
    that.$bind(proparr, collection).then(resolve).catch(reject);
  });
}

export function bindCombineArrays(that, combined, props, sort) {
  //log.log(`bindCombineArrays "${combined}" <== ${props}`);
  let combine = () => {
    let arr = props.reduce((res, p) => res.concat(that[p]), []);
    if (sort)
      arr = sort(arr);
    //log.log(`refreshing combined "${combined}" =`, arr);
    that.$set(that, combined, arr);
  };
  // watches each property
  props.forEach((p) => { that.$watch(p, combine); });
}

export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function epoch() {
  return Math.floor(new Date() / 1000);
}

export function epochms() {
  return Math.floor(new Date());
}

//https://stackoverflow.com/a/19746771/318839
export function arraysEqual(array1, array2) {
  return array1.length === array2.length && array1.every((value, index) => value === array2[index])
}

//https://stackoverflow.com/a/12646864/318839
export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
}

//https://stackoverflow.com/a/55594573/318839
export let inject = (str, obj) => str.replace(/\${(.*?)}/g, (x,g)=> obj[g]);

export let injectPath = (str, obj) => {
  return str.replace(/%{(.*?)}/g, (x,g) => {
    let [o, p] = g.split('.');
    return obj[o][p];
  });
};

//https://stackoverflow.com/a/38340730/318839
export let removeNull = obj => {
  Object.keys(obj).forEach(key => obj[key] == null && delete obj[key]);
  return obj;
};

export let removeUndefined = obj => {
  Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
  return obj;
};

export let removeNullInArray = arr => {
  return arr.filter(n => n != null);
}

//https://stackoverflow.com/a/28191966/318839
export let getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
}

export let getKeysByValue = (object, value) => {
  return Object.keys(object).filter(key => object[key] === value);
}


//https://stackoverflow.com/a/1431110/318839
export function setCharAt(str,index,chr) {
  if(index > str.length-1) return str;
  return str.substring(0,index) + chr + str.substring(index+1);
}

export function objectFilter(obj, condition) {
  let result = {}, key;
  for (key in obj) {
    if (obj.hasOwnProperty(key) && condition(obj[key], key)) {
        result[key] = obj[key];
    }
  }
  return result;
}

export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

//https://stackoverflow.com/a/37164538/318839
export function mergeDeep(target, source) {
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target))
          Object.assign(output, { [key]: source[key] });
        else
          output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

export async function fetchBlob(url) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => { 
      if (xhr.status > 400) // https://stackoverflow.com/a/36182963/318839
        reject(new Error(url + ` error ${xhr.status}`));
      else
        resolve(xhr.response);
    }
    xhr.onerror = reject;
    xhr.open('GET', url);
    xhr.send();
  });
}

export function parseBool(value) {
  return !!(parseInt(value) || value === "true");
}

export function leftJoin(objArr1, objArr2, key1, key2) {
  return objArr1.map(
    anObj1 => Object.assign(Object.assign({}, anObj1),
      objArr2.find(anObj2 => anObj1[key1] === anObj2[key2]))
  );
}

export function trueOrIncludes(value, options) {
  return value
    && (value === true 
      || value.includes(options));
}

// https://stackoverflow.com/a/1349426
export function randomString(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
 }
 return result;
}

// https://stackoverflow.com/a/7616484/318839
export function hashCode(s) {
  var hash = 0, i, chr;
  if (s.length === 0) return hash;
  for (i = 0; i < s.length; i++) {
    chr   = s.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return (hash >>> 0);
}