import Vue from 'vue';
import App from './App.vue';
import Router from "vue-router";
import { firestorePlugin } from 'vuefire';

import NotFoundComponent from "./views/NotFoundComponent.vue"

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
let packagejson = require("../package.json");

if (window.location.hostname != "localhost"
  && !window.location.hostname.startsWith("192.168.")) 
{
  Sentry.init({
    dsn: 'https://252e5061b4c345438ef2f7637e9ff018@sentry.io/1500943',
    integrations: [
      new Integrations.Vue({Vue, attachProps: true, console: false}),
      new Sentry.Integrations.Breadcrumbs({ console: false })
    ],
    release: packagejson.name + "@" + packagejson.version,
  });
}

import { library } from '@fortawesome/fontawesome-svg-core'
import { faMicrophone, faMicrophoneSlash, faPhone, faGhost, faBars, faIdCard, faLock, faCheckCircle, 
  faShieldAlt, faThumbsUp, faImages, faDesktop, faVideo, faVolumeMute, faExpand, faCompress, faWindowClose, 
  faWindowRestore, faCopy, faCalendar, faShare, faEdit, faStar, faSun, faGear, faPencil, faTrash, faArchive,
  faStickyNote, faComment, faComments, faTasks, faSignOut, faInfoCircle, faSearchPlus, faUpload } from '@fortawesome/free-solid-svg-icons' 
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome' 
import "./filters"

library.add(faMicrophone, faMicrophoneSlash, faPhone, faGhost, faBars, faIdCard, faLock, faCheckCircle, 
  faShieldAlt, faThumbsUp, faImages, faDesktop, faVideo, faVolumeMute, faExpand, faCompress, faWindowClose, 
  faWindowRestore, faCopy, faCalendar, faShare, faEdit, faStar, faSun, faGear, faPencil, faTrash, faArchive,
  faStickyNote, faComment, faComments, faTasks, faSignOut, faInfoCircle, faSearchPlus, faUpload);

import BootstrapVue from 'bootstrap-vue';
import VueJsonPretty from 'vue-json-pretty';
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('vue-json-pretty', VueJsonPretty);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(firestorePlugin);
Vue.use(Router);
Vue.use(require('vue-shortkey'), { prevent: ['input', 'textarea'] })

import { appConfig, appConfigPlugin } from "@/services/appconfig";
Vue.use(appConfigPlugin);
import { debugPlugin } from "@/services/debug";
Vue.use(debugPlugin);
import { logPlugin } from "@/services/log";
Vue.use(logPlugin);
import debugObj from "./components/debugObj";
import debugGroup from "./components/debugGroup";
Vue.component('debug-obj', debugObj);
Vue.component('debug-group', debugGroup);
import { storePlugin } from "@/views/notes/store";
Vue.use(storePlugin);

import "./main.scss"
import './registerServiceWorker'
import { notesRoute } from './views/notes/routes.js';
import { fnStatsRoute } from './views/fnstats/routes.js';
import { testsRoute } from "./views/tests/tests.js";
import { adminRoute } from "./views/admin/routes.js";
import { vidRoute } from './views/vid/routes.js';

// Guest Route
let guestRoute = {
  path: "/guest",
  component: () => import("./views/guest/GuestRouter.vue"),
  children: [
    {
      path: "",
      component: () => import("./views/guest/Home.vue")
    },
    {
      path: "event/:eventId",
      component: () => import("./views/guest/Guest.vue")
    },
    {
      path: "event/:eventId/watch/:recordingId",
      component: () => import("./views/guest/EventWatch.vue")
    },
    {
      path: "home",
      component: () => import("./views/guest/Home.vue"),
    },
    {
      name: "signup",
      path: "signup",
      component: () => import("./views/guest/Signup.vue"),
      props: true,
      meta: { nav:false, noUser:true }
    },
    {
      path: "account",
      component: () => import("./views/guest/Signup.vue"),
    },
    {
      path: "form",
      alias: "profile",
      component: () => import("./views/guest/Form.vue")
    },
    {
      path: "matches",
      component: () => import("./views/guest/Matches.vue")
    },
    {
      path: "allpictures",
      component: () => import("./views/guest/AllPictures.vue")
    },
    {
      path: "debug",
      component: () => import("./views/guest/Debug.vue")
    },
    {
      path: "join/:eventid",
      component: () => import("./views/guest/Join.vue"),
      meta: { noUser:true }
    },
    {
      path: "events/list",
      alias: 'events',
      component: () => import("./views/guest/EventsList.vue")
    },
    {
      path: "events/create",
      component: () => import("./views/guest/EventsCreate.vue")
    },
    {
      path: "events/create/:eventType",
      component: () => import("./views/guest/EventsCreateType.vue")
    },
    {
      path: "reference",
      component: () => import("./views/guest/Reference.vue")
    },
  ]
};

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // Public URL
    {
      path: "/",
      name: "home",
      redirect: appConfig.homeRedirect,
      component: () => import("./views/Home.vue")
    },
    {
      path: "/terms-of-use",
      component: () => import("./views/TermsOfUse.vue"),
    },
    {
      path: "/privacy-policy",
      component: () => import("./views/PrivacyPolicy.vue")
    },
    {
      path: "/no-access",
      component: () => import("./views/NoAccess.vue")
    },
    // Guest
    guestRoute,
    // Admin
    adminRoute,
    // Tests
    testsRoute,
    // Notes
    notesRoute,
    // FnStats
    fnStatsRoute,
    // Vid
    vidRoute,
    // Game HT
    {
      path: "/game/ht",
      component: () => import("./views/GameHT.vue")
    },
    { 
      path: '*', 
      component: NotFoundComponent 
    }
  ]
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
