
import NotFoundComponent from "@/views/NotFoundComponent.vue"

export let fnStatsRoute = { 
  path: "/fnstats",
  component: () => import("./FnStatsRouter.vue"),
  children: [
    {
      path: "charts",
      component: () => import("./Charts.vue"),
    },
    {
      path: "list",
      component: () => import("./List.vue"),
    },
    {
      path: "list-all",
      component: () => import("./ListAll.vue"),
    },
    {
      path: "login",
      meta: { nextRoute: "/fnstats/charts" },
      component: () => import("./Login.vue"),
    },
    {
      path: "island/:islandCode",
      component: () => import("./Island.vue"),
    },
    {
      path: "charts2",
      component: () => import("./Charts2.vue"),
    },
    {
      path: "charts3",
      component: () => import("./Charts3.vue"),
    },
    {
      path: "bookmarks",
      component: () => import("./Bookmarks.vue"),
    },
    {
      path: "trends",
      component: () => import("./Trends.vue"),
    },
    {
      path: '*',
      component: NotFoundComponent 
    }
  ]
}



