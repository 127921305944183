<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
}
</script>

<style lang="scss">

@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';

@media (min-width: 999px) {
    .container{
        max-width: 789px;
    }
}

.vh-main {
  height: calc(100vh - 42px);
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}
</style>
