//console.log("log-client");

import * as Sentry from '@sentry/browser';

function sentryParams(head, tail) {
  if (tail.length == 0) {
    return { message: head, data: {} };
  } else if (tail.length == 1) {
    let obj = tail[0];
    if (typeof obj == "object" && !Array.isArray(obj))
      return { message: head, data: obj };
    else
      return { message: head, data: { data: tail[0] } };
  } else {
    let data = {};
    for (let index in tail)
      data["p" + index] = tail[index];
    return { message: head, data: data };
  }
}

/* eslint-disable no-console */
function sentryLog(head, ...tail) {
  if (this.enabled)
    console.log.apply(console, [`[${this.namespace}] ${head}`, ...tail]);
  let { message, data } = sentryParams(head, tail);
  sentryBreadcrum(this.namespace, message, data);
  if (this.output) this.output(message, tail);
}

function sentryError(head, ...tail) {
  console.error.apply(console, [`[${this.namespace}] [error] ${head}`, ...tail]);
  let { message, data } = sentryParams(head, tail);
  sentryBreadcrum(this.namespace, message, data, Sentry.Severity.Error);
  Sentry.captureMessage(`Error: ${message} ${data}`);
  if (this.output) this.output(message, tail);
}

function sentryBreadcrum(namespace, message, data, level) {
  if (!level)
    level =  Sentry.Severity.Info;
  Sentry.addBreadcrumb({
    category: namespace,
    message: message,
    data: data,
    level: level,
  });
}

export function getLog(namespace, enable) {
  var log = {};
  log.namespace = namespace;
  log.enabled = !(enable === false);
  log.output = null;
  log.log = sentryLog.bind(log);
  log.error = sentryError.bind(log);
  log.assert = console.assert.bind(console);
  log.captureException = (e) => Sentry.captureException(e);
  log.time = console.time.bind(console);
  log.timeEnd = console.timeEnd.bind(console);
  log.info = sentryLog.bind(log);
  return log;
}

import { database } from "./db";

export function formatParams(params) {
  let result = "";
  params.forEach((p, i) => {
    if (i > 0)
      result += ", ";
    result += formatObject(p);
  });
  return result;
}

export function formatObject(p) {
  let v = JSON.stringify(p);
  return (v != "{}") ? v : `${p}`;
}

// exports log through database
export function setDbLog(log, key, id) {
  if (!key) {
    log.rootDb = null;
    log.output = null
  } else {
    log.rootDb = database.ref(`Logs/${key}`);
    log.output = (message, data) => {
      log.rootDb.push({ id, message: `${message}`, data: formatParams(data), time:new Date().getTime() });
    }
  }
}

export function sentryUser(user) {
  Sentry.configureScope((scope) => {
    scope.setUser(user);
  });
}

export let logPlugin = {
  install(Vue) {
    Vue.prototype.$log = getLog("vue");
  }
}

