
import NotFoundComponent from "@/views/NotFoundComponent.vue"

export let vidRoute = { 
  path: "/vid",
  component: () => import("./VidRouter.vue"),
  children: [
    {
      path: "upload",
      component: () => import("./Upload.vue"),
    },
    {
      path: "list",
      component: () => import("./List.vue"),
    },
    {
      path: "watch/:id",
      meta: { noNav: true, noFooter: true },
      component: () => import("./Watch.vue"),
    },
    {
      path: "login",
      meta: { nextRoute: "/vid/list" },
      component: () => import("../fnstats/Login.vue"),
    },
    {
      path: "ops",
      component: () => import("./Ops.vue"),
    },
    {
      path: "settings",
      component: () => import("./Settings.vue"),
    },
    {
      path: '*',
      component: NotFoundComponent 
    }
  ]
}



