import { app, useEmulators } from "./firebase";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

export let functions = getFunctions(app);

export let useFunctionsEmulators = /* true; // || */ useEmulators;

if (useFunctionsEmulators && (window.location.hostname == "localhost" || window.location.hostname.startsWith("192.168.")))
  connectFunctionsEmulator(functions, `${window.location.hostname}`, 5001);
else
  useFunctionsEmulators = false;

export let helloWorld = httpsCallable(functions, "auth-helloWorld");
export let upgradeAuth = httpsCallable(functions, "auth-upgradeAuth");
export let setEventAutomation = httpsCallable(functions, "event-setEventAutomation");
export let emulatorScheduledEventUpdate = httpsCallable(functions, "event-emulatorScheduledEventUpdate");
export let testsEmulatorScheduledEventUpdate = httpsCallable(functions, "tests-emulatorScheduledEventUpdate");
export let testsFirestorePerformanceNormal = httpsCallable(functions, "tests-firestorePerformanceNormal");
export let testsFirestorePerformanceSlow = httpsCallable(functions, "tests-firestorePerformanceSlow");
export let getTimeMS = httpsCallable(functions, "event-getTimeMS");
export let getChannelToken = httpsCallable(functions, "agora-getChannelToken");
export let checkIslandStats = httpsCallable(functions, "scrapfn-checkIslandStats");
export let checkIslandsStats = httpsCallable(functions, "scrapfn-checkIslandsStats");

export let callable = (name) => httpsCallable(functions, name);