export let debugPlugin = {
  install(Vue) {
    Vue.prototype.$debug = Vue.observable({
      // Guest
      isAvailable: false,
      isOn: false,
      lowDef: false,
      // Admin
      isSuperAdmin: false,
      isSuperAdminOn: false,
      isDeveloperOn: false,
    });
  }
}
